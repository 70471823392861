<template>
  <div>
    <v-app>
      <div class>
        <div class="nested-table">
          <v-data-table
            v-can="'list-academic-periods'"
            style
            :single-expand="true"
            :expanded.sync="level1Table.expanded"
            show-expand
            :options.sync="level1Table.options"
            :server-items-length="level1Table.totalYears"
            :loading="level1Table.loading"
            loading-text="Loading... Please wait"
            v-model="selectedItems"
            :headers="level1Table.headers"
            :items="level1Table.years"
            item-key="id"
            :single-select="true"
            class="elevation-1 level1 parentTable"
            @click:row="openExpend"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <!-- <h3>All Academic Periods Data</h3> -->
                <v-toolbar-title class="main-color"
                  >{{ $t("All Academic Periods Data") }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
                <v-icon
                  v-can="'add-academic-periods'"
                  class="mr-2 main-color"
                  large
                  title="Add new item"
                  @click="openDialog"
                  >add_circle</v-icon
                >
                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  to="/systemlogging/academic_period"
                  v-if="activitylogPermission"
                  target="_blank"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>

                <v-dialog
                  v-model="level1Table.dialog"
                  persistent
                  max-width="500px"
                >
                  <!-- start dialog for add and edit -->
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form ref="addEditForm" v-model="addEditvalid">
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <div class="form-group">
                                <label>{{ $t("Name") }}</label>
                                <v-text-field
                                  v-model="level1Table.editedItem.en.name"
                                  solo
                                  @focus="onFocus()"
                                  :rules="[
                                    validationRules.required,
                                    validationRules.maxLength50,
                                  ]"
                                ></v-text-field>
                                <div v-if="validation_errors.en">
                                  <p
                                    class="red--text"
                                    v-for="(error, index) in validation_errors
                                      .en.name"
                                    :key="index"
                                  >
                                    {{ error }}
                                  </p>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <div class="form-group">
                                <label>{{ $t("Arabic Name") }}</label>
                                <v-text-field
                                  v-model="level1Table.editedItem.ar.name"
                                  solo
                                  @focus="onFocus()"
                                  :rules="[
                                    CheckArabicCharactersOnly(
                                      level1Table.editedItem.ar.name
                                    ),
                                    validationRules.maxLength50,
                                  ]"
                                ></v-text-field>
                                <div v-if="validation_errors.ar">
                                  <p
                                    class="red--text"
                                    v-for="(error, index) in validation_errors
                                      .ar.name"
                                    :key="index"
                                  >
                                    {{ error }}
                                  </p>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <label>{{ $t("Short Name") }}</label>
                              <v-text-field
                                v-model="level1Table.editedItem.short_name"
                                solo
                                @focus="onFocus()"
                                :rules="[
                                  validationRules.required,
                                  validationRules.maxLength6,
                                ]"
                              ></v-text-field>
                              <div v-if="validation_errors.short_name">
                                <p
                                  class="red--text"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.short_name"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>
                            <v-col
                              v-if="
                                selectedItems.length == 0 ||
                                (selectedItems.length > 0 &&
                                  selectedItems[0].level == 1 &&
                                  level1Table.editedIndex > -1)
                              "
                              cols="12"
                              sm="6"
                              md="6"
                            >
                              <div class="form-group">
                                <label>{{ $t("Hijri Name") }}</label>
                                <v-text-field
                                  v-model="level1Table.editedItem.hijri_name"
                                  solo
                                ></v-text-field>
                                <!-- <div v-if="validation_errors.short_name">
                                <p
                                  class="red--text"
                                  v-for="(error,
                                  index) in validation_errors.short_name"
                                  :key="index"
                                >{{ error }}</p>
                              </div>-->
                              </div>
                            </v-col>
                          </v-row>
                          <!-- Hiry Name  -->
                          <!-- <v-row
                            v-if="
                              selectedItems.length == 0 ||
                                (selectedItems.length > 0 &&
                                  selectedItems[0].level == 1 &&
                                  level1Table.editedIndex > -1)
                            "
                          >

                          </v-row> -->
                          <!-- /Hijry Name -->
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <div class="form-group">
                                <label>{{ $t("Start Date") }}</label>
                                <v-text-field
                                  v-model="level1Table.editedItem.start_date"
                                  id="startDate"
                                  solo
                                  autocomplete="off"
                                  @focus="onFocus()"
                                  :rules="[
                                    validationRules.required,
                                    startDateRule(
                                      level1Table.editedItem.start_date,
                                      level1Table.editedItem.end_date,
                                      'The start date must be a date before end date.'
                                    ),
                                  ]"
                                  @keydown.prevent
                                ></v-text-field>
                                <div v-if="validation_errors.start_date">
                                  <p
                                    class="red--text"
                                    v-for="(
                                      error, index
                                    ) in validation_errors.start_date"
                                    :key="index"
                                  >
                                    {{ error }}
                                  </p>
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <div class="form-group">
                                <label>{{ $t("End Date") }}</label>
                                <v-text-field
                                  v-model="level1Table.editedItem.end_date"
                                  id="endDate"
                                  solo
                                  autocomplete="off"
                                  @focus="onFocus()"
                                  :rules="[
                                    validationRules.required,
                                    startDateRule(
                                      level1Table.editedItem.start_date,
                                      level1Table.editedItem.end_date,
                                      'The end date must be a date after start date.'
                                    ),
                                  ]"
                                  @keydown.prevent
                                ></v-text-field>
                                <div v-if="validation_errors.end_date">
                                  <p
                                    class="red--text"
                                    v-for="(
                                      error, index
                                    ) in validation_errors.end_date"
                                    :key="index"
                                  >
                                    {{ error }}
                                  </p>
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              v-if="
                                selectedItems.length == 0 ||
                                (selectedItems.length > 0 &&
                                  selectedItems[0].level == 1 &&
                                  level1Table.editedIndex > -1)
                              "
                            >
                              <div class="form-group checkbox">
                                <v-checkbox
                                  v-model="level1Table.editedItem.is_active"
                                  class="mx-2"
                                  :label="
                                    $i18n.t('This Academic Year Is Active')
                                  "
                                  @change="onFocus()"
                                ></v-checkbox>
                              </div>
                              <div v-if="validation_errors.is_active">
                                <p
                                  class="red--text"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.is_active"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="modal-btn-cancel cancelBtn"
                        text
                        @click="close"
                        >{{ $t("Cancel") }}</v-btn
                      >
                      <v-btn
                        class="modal-btn-save saveBtn"
                        text
                        @click="checkIfDatesChanges"
                        :disabled="disabledSubmitbtn"
                        :loading="disabledSubmitbtn"
                        >{{ $t("Save") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>

                  <!-- end dialog for add/edit -->
                </v-dialog>
                <v-dialog
                  v-model="level1Table.admissionDialog"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{
                        $t("Change Admission Status/Alias")
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <p
                              class="red--text"
                              v-if="level1Table.addmissionStatusError != ''"
                            >
                              {{ level1Table.addmissionStatusError }}
                            </p>
                            <label>{{ $t("Alias") }}</label>
                            <v-text-field
                              v-model="level1Table.editedAdmissionItem.alias"
                              solo
                              placeholder="admission/type your own alias"
                              @focus="onFocus()"
                            ></v-text-field>
                            <p
                              class="red--text"
                              v-for="(error, index) in validation_errors.alias"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </v-col>

                          <v-col cols="12" sm="12" md="12">
                            <label>{{ $t("Admission is open") }}</label>
                            <v-switch
                              v-model="
                                level1Table.editedAdmissionItem
                                  .is_addmission_open
                              "
                              solo
                              @change="saveAdmissionStatus"
                            ></v-switch>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            style="margin-top: 5%"
                          >
                            <v-btn
                              v-if="
                                level1Table.editedAdmissionItem.alias &&
                                level1Table.editedAdmissionItem
                                  .is_addmission_open &&
                                level1Table.addmissionStatusError == ''
                              "
                              color="blue darken-1"
                              text
                              :disabled="disabledSubmitbtn"
                              @click="openAdmissionLink"
                              >{{ $t("Open Admission Link") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="modal-btn-cancel cancelBtn"
                        text
                        @click="closeAdmissionStatus"
                        >{{ $t("Cancel") }}</v-btn
                      >
                      <v-btn
                        class="modal-btn-save saveBtn"
                        text
                        :disabled="disabledSubmitbtn"
                        @click="saveAdmissionStatus"
                        >{{ $t("Save") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.en.name="{ item }">
              <v-tooltip bottom max-width="2800px">
                <template v-slot:activator="{ on }">
                  <span dark v-on="on" v-if="item.en.name">{{
                    item.en.name.slice(0, 20)
                  }}</span>
                </template>
                <span>{{ item.en.name }}</span>
              </v-tooltip>

              <v-icon color="success" small v-if="item.is_active == 1"
                >check_circle</v-icon
              >
            </template>
            <template v-slot:item.start_date="{ item }">
              <p>
                <v-icon color="success" small>calendar_today</v-icon>
                {{ item.start_date }}
              </p>
            </template>
            <template v-slot:item.end_date="{ item }">
              <p>
                <v-icon color="success" small>calendar_today</v-icon>
                {{ item.end_date }}
              </p>
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="clone(item.id)"
                v-if="cloneBtnVisible"
                :title="$t('Clone Academic Period')"
                >file_copy</v-icon
              >
              <v-icon
                v-if="editBtnVisible"
                small
                class="mr-2"
                @click="editItem(item)"
                :title="$t('Edit')"
                >edit</v-icon
              >

              <i
                class="fas fa-user-graduate"
                @click="changeAdmissionStatus(item)"
                v-if="changeBtnVisible"
                :title="$t('Change Admission Status/Alias')"
              ></i>
              <v-icon
                small
                @click="openConfirmDeleteDialog(item)"
                v-if="deleteBtnVisible"
                :title="$t('Delete')"
                >delete</v-icon
              >
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <!-- here goes the second table -->
              <td :colspan="level1Table.headers.length + 2">
                <div>
                  <h4 class="text-center table-title">{{ item.name }}</h4>
                </div>
                <div class="space-top"></div>
                <!-- Second Table Start -->
                <v-data-table
                  v-if="listAcademicVisible"
                  style
                  :single-expand="false"
                  :expanded.sync="level2Table.expanded"
                  show-expand
                  :options.sync="level2Table.options"
                  :loading="level2Table.loading"
                  loading-text="Loading semesters... Please wait"
                  v-model="selectedItems"
                  :headers="level1Table.headers"
                  :items="item.childrens"
                  item-key="id"
                  :single-select="true"
                  @click:row="openExpend"
                  hide-default-footer
                  class="level2"
                >
                  <template v-slot:item.start_date="{ item }">
                    <p>
                      <v-icon color="success" small>calendar_today</v-icon>
                      {{ item.start_date }}
                    </p>
                  </template>
                  <template v-slot:item.end_date="{ item }">
                    <p>
                      <v-icon color="success" small>calendar_today</v-icon>
                      {{ item.end_date }}
                    </p>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                      :title="$t('Edit')"
                      v-if="editBtnVisible"
                      >edit</v-icon
                    >
                    <v-icon
                      small
                      @click="openConfirmDeleteDialog(item)"
                      v-if="deleteBtnVisible"
                      :title="$t('Delete')"
                      >delete</v-icon
                    >
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="level1Table.headers.length + 2">
                      <div>
                        <h4 class="text-center table-title2">
                          {{ item.name }}
                        </h4>
                      </div>
                      <div class="space-top"></div>
                      <!-- Third Table Start -->
                      <v-data-table
                        v-if="listAcademicVisible"
                        style
                        :single-expand="false"
                        :options.sync="level3Table.options"
                        :loading="level3Table.loading"
                        loading-text="Loading semesters... Please wait"
                        v-model="selectedItems"
                        :headers="level1Table.headers"
                        :items="item.grandchildrens"
                        item-key="id"
                        class="level3"
                        hide-default-footer
                        :footer-props="{
                          showFirstLastPage: true,
                          firstIcon: 'mdi-arrow-collapse-left',
                          lastIcon: 'mdi-arrow-collapse-right',
                          prevIcon: 'mdi-minus',
                          nextIcon: 'mdi-plus',
                        }"
                      >
                        <template v-slot:item.start_date="{ item }">
                          <p style="color: white">
                            <v-icon color="white" small>calendar_today</v-icon>
                            {{ item.start_date }}
                          </p>
                        </template>
                        <template v-slot:item.end_date="{ item }">
                          <p style="color: white">
                            <v-icon color="white" small>calendar_today</v-icon>
                            {{ item.end_date }}
                          </p>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                            :title="$t('Edit')"
                            v-if="editBtnVisible"
                            >edit</v-icon
                          >
                          <v-icon
                            small
                            @click="openConfirmDeleteDialog(item)"
                            v-if="deleteBtnVisible"
                            :title="$t('Delete')"
                            >delete</v-icon
                          >
                        </template>
                      </v-data-table>
                      <div class="space-bottom"></div>
                      <!-- Third Table End -->
                    </td>
                  </template>
                </v-data-table>
                <div class="space-bottom"></div>
                <!-- Second Table End -->
              </td>
            </template>
          </v-data-table>

          <!-- ====================pagination===================== -->
          <div class="text-center pt-2" v-can="'list-academic-periods'">
            <v-pagination
              v-model="page"
              :length="length"
              :circle="circle"
              :page="page"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
            ></v-pagination>
            <!-- :total-visible="totalVisible" -->
          </div>
        </div>

        <v-snackbar v-model="snack" color="error" :right="true" :top="true">
          <!-- {{ snackText }} -->

          <span class="white--text">{{ snackText }}</span>

          <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
        </v-snackbar>

        <v-dialog
          v-model="confirmdialog"
          persistent
          max-width="300"
          class="dialog_confirm"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Confirmation Message")
            }}</v-card-title>

            <v-card-text>{{
              $t("Are you sure you want to delete this item ?")
            }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="modal-btn-cancel"
                @click="confirmdialog = false"
                id="noBtn"
                >{{ $t("No") }}</v-btn
              >
              <v-btn
                @click="deleteItem(deletedItem)"
                class="modal-btn-save"
                id="yesBtn"
                >{{ $t("Yes") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <AcademicPeriodGradeComponent
          v-bind:data="grades"
          v-bind:academic_period_id="academic_period_id"
          v-if="listAcademicGradesVisible"
        ></AcademicPeriodGradeComponent>

        <v-dialog
          v-model="dialogConfirm"
          persistent
          max-width="300"
          class="dialog_confirm"
        >
          <v-card>
            <v-card-title class="headline">{{
              $t("Confirmation Message")
            }}</v-card-title>

            <v-card-text>{{
              $t(
                "Editing academic year dates will Delete all the data Related to this academic year edit anyway ?"
              )
            }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="modal-btn-cancel noBtn"
                @click="cancelEdit()"
                id="noBtn"
                ref="always_focus"
                >{{ $t("No") }}</v-btn
              >
              <v-btn
                id="yesBtn"
                @click="save()"
                class="modal-btn-save yesBtn"
                >{{ $t("Yes") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import AcademicPeriodGradeComponent from "../../components/AcademicPeriodGradeComponent";
import ACL from "../../acl";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
moment.locale("es");

import "../../assets/jquery.calendars.package-2.1.1/css/humanity.calendars.picker.css";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.js";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.plus.js";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.islamic.js";

import "../../assets/jquery.calendars.package-2.1.1/js/jquery.plugin.js";
import "../../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.picker.js";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "AcademicPeriods",
  components: {
    AcademicPeriodGradeComponent,
  },
  mixins: [validationMixin],
  data() {
    return {
      activitylogPermission: ACL.checkPermission("activitylog"),

      dialogConfirm: false,
      loading: false,
      editBtnVisible: ACL.checkPermission("edit-academic-periods"),
      deleteBtnVisible: ACL.checkPermission("delete-academic-periods"),
      changeBtnVisible: ACL.checkPermission(
        "change-academic-period-admission-status"
      ),
      cloneBtnVisible: ACL.checkPermission("clone-academic-periods"),
      listAcademicVisible: ACL.checkPermission("list-academic-periods"),
      listAcademicGradesVisible: ACL.checkPermission(
        "list-academic-periods-grades"
      ),
      confirmdialog: false,
      addEditvalid: false,
      deletedItem: {},
      grades: [],
      academic_period_id: "",
      snack: false,
      snackColor: "",
      snackText: "",
      level1Table: {
        expanded: [],
        dialog: false,
        admissionDialog: false,

        max25chars: (v) => v.length <= 25 || "Input too long!",
        totalYears: 0,
        menu: false,
        menu2: false,
        years: [],
        loading: false,
        options: {
          itemsPerPage: 5,
        },
        headers: [
          // {
          //   text: "ID",
          //   align: "left",
          //   sortable: false,
          //   value: "id"
          // },
          { text: this.$i18n.t("Name"), value: "en.name" },
          { text: this.$i18n.t("Arabic Name"), value: "ar.name" },
          { text: this.$i18n.t("Short Name"), value: "short_name" },
          { text: this.$i18n.t("Hijri Name"), value: "hijri_name" },
          {
            text: this.$i18n.t("Start Date"),
            value: "start_date",
            sortable: false,
          },
          {
            text: this.$i18n.t("End Date"),
            value: "end_date",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "action",
            sortable: false,
            width: "12%",
          },
        ],
        editedIndex: -1,
        editedItem: {
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
          short_name: "",
          hijri_name: "",
          start_date: "",
          end_date: "",
          index_level1: "",
          index_level2: "",
          indel: "",
          typex_level3: "",
          level: "year",
          type: "year",
          is_active: "",
        },
        defaultItem: {
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
          short_name: "",
          hijri_name: "",
          start_date: "",
          end_date: "",
          index_level1: "",
          index_level2: "",
          index_level3: "",
          level: "",
          is_active: "",
        },
        editedAdmissionItem: {
          id: "",
          alias: "",
          is_addmission_open: false,
        },
        defaultAdmissionItem: {
          id: "",
          alias: "",
          is_addmission_open: false,
        },
        addmissionStatusError: "",
      },
      // end of level 1 table data

      level2Table: {
        expanded: [],
        loading: false,
        options: {
          itemsPerPage: 20,
        },
      },
      level3Table: {
        expanded: [],
        loading: false,
        options: {
          itemsPerPage: 5,
        },
      },
      // end of level 2 table data
      selectedItems: [],
      validation_errors: [],
      disabledSubmitbtn: false,

      //pagination options
      totalPages: 0,
      page: 1,
      circle: true,
      length: 0,
      // totalVisible: 0

      // end pagination

      formTitle: this.$i18n.t("New Academic Period"),
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.getDataFromApi(this.$route.query.page);
        }
        // let pageNumber = this.$router.currentRoute.params.pageNumber;
        // this.getDataFromApi(pageNumber);
      },
    },
    page: function () {
      this.$router.push(
        {
          path: "/academics?page=" + this.page,
        },
        () => {}
      );
    },
    "level1Table.options": {
      handler() {
        if (
          this.level1Table.options.sortBy.length > 0 &&
          this.level1Table.options.sortDesc.length > 0
        ) {
          this.getDataFromApi(this.level1Table.options.page);
        }
      },
      deep: true,
    },
    "level1Table.dialog"(val) {
      val || this.close();
      // this.autoFocusDialog();
    },
    // confirmdialog: {
    //   handler() {
    //     this.autoFocusDialog();
    //   }
    // },
    // "level1Table.admissionDialog": {
    //   handler() {
    //     this.autoFocusDialog();
    //   }
    // }
  },
  computed: {
    // formTitle() {
    //   // return this.level1Table.editedIndex === -1
    //   //   ? this.$i18n.t("New Academic Period")
    //   //   : this.$i18n.t("Edit Academic Period");
    // },
  },
  methods: {
    cancelEdit() {
      console.log("cancel");
      this.dialogConfirm = false;
      this.disabledSubmitbtn = false;
    },

    getDataFromApi(page = 1) {
      let sort_type = "";
      let column = "";
      if (this.level1Table.options.sortBy.length > 0) {
        column = this.level1Table.options.sortBy[0];
        sort_type = this.level1Table.options.sortDesc[0] ? "DESC" : "ASC";
      }
      axios
        .get(
          this.getApiUrl +
            "/school/showAcademicPeriod?page=" +
            page +
            "&sort_type=" +
            sort_type +
            "&column=" +
            column,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          /* =====================pagination============================== */
          this.page = response.data.data.current_page;
          this.totalPages = response.data.data.last_page;
          this.length = this.totalPages;
          if (this.totalPages > 5) this.totalVisible = 5;
          /* ===================== end pagination============================== */

          this.level1Table.years = response.data.data.data;
          this.level1Table.total = response.data.data.total;
          this.level1Table.totalYears = response.data.data.total;

          const {
            sortBy,
            sortDesc,
            // eslint-disable-next-line no-unused-vars
            page,
            // eslint-disable-next-line no-unused-vars
            itemsPerPage,
          } = this.level1Table.options;

          // let items = this.getDesserts();
          // eslint-disable-next-line no-unused-vars
          const total = this.level1Table.years.length;

          if (sortBy.length === 1 && sortDesc.length === 1) {
            this.level1Table.years = this.level1Table.years.sort((a, b) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }
        });
    },
    editItem(item) {
      if (item.level == 3) {
        this.selectedItems = [];
        this.selectedItems.push(item);
      }

      var _this = this;
      $(document).ready(function () {
        $("#startDate").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.level1Table.editedItem.start_date = dateH;
            } else {
              _this.level1Table.editedItem.start_date = "";
            }
          },
        });
        $("#endDate").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.level1Table.editedItem.end_date = dateH;
            } else {
              _this.level1Table.editedItem.end_date = "";
            }
          },
        });
      });
      if (item.level == 1) {
        this.level1Table.editedIndex = item.index_level1;
        this.formTitle = this.$i18n.t("Edit Academic Period");
      } else if (item.level == 2) {
        this.level1Table.editedIndex = item.index_level2;
        this.formTitle = this.$i18n.t("Edit Semester");
      } else if (item.level == 3) {
        this.level1Table.editedIndex = item.index_level3;
        this.formTitle = this.$i18n.t("Edit Quarter");
      }
      this.level1Table.editedItem = Object.assign({}, item);
      this.level1Table.dialog = true;
      // this.selectedItems.splice(0, 1);
    },

    openConfirmDeleteDialog(item) {
      this.confirmdialog = true;
      this.deletedItem = item;
    },

    deleteItem(item) {
      let index = 0;
      if (item.level == 1) index = item.index_level1;
      if (item.level == 2) index = item.index_level2;
      if (item.level == 3) index = item.index_level3;
      let id = item.id;
      let checkChildren = [];
      if (item.level == 1) {
        checkChildren = item.childrens;
      } else if (item.level == 2) {
        checkChildren = item.grandchildrens;
      }
      if (checkChildren.length == 0) {
        axios
          .get(this.getApiUrl + "/school/deleteAcademicPeriod/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            if (item.level == 1) {
              this.getDataFromApi(this.page);
              this.level1Table.years.splice(index, 1);
            } else if (item.level == 2) {
              this.level1Table.years[item.index_level1].childrens.splice(
                index,
                1
              );
            } else if (item.level == 3) {
              this.level1Table.years[item.index_level1].childrens[
                item.index_level2
              ].grandchildrens.splice(index, 1);
            }
            this.confirmdialog = false;
          });
      } else {
        this.snack = true;
        this.snackText = "Please delete child items first";
      }

      this.disabledSubmitbtn = false;
    },

    close() {
      this.validation_errors = [];
      this.level1Table.dialog = false;
      setTimeout(() => {
        // let type = this.level1Table.editItem.type;
        this.level1Table.editedItem = Object.assign(
          {},
          {
            en: {
              name: "",
            },
            ar: {
              name: "",
            },
            short_name: "",
            start_date: "",
            hijri_name: "",
            end_date: "",
            index_level1: "",
            index_level2: "",
            index_level3: "",
            level: "",
            type: "",
          }
        );
        this.level1Table.editedIndex = -1;
      }, 300);

      this.selectedItems = [];
    },

    checkValidation() {
      this.$refs.addEditForm.validate();
    },
    resetValidation() {
      this.$refs.addEditForm.resetValidation();
    },
    checkIfDatesChanges() {
      if (this.addEditvalid == false) {
        this.checkValidation();
      } else {
        let item = this.level1Table.editedItem;

        this.disabledSubmitbtn = true;

        // check if academic period have semester and quarter on it
        if (this.level1Table.editedIndex > -1) {
          if (item.level == 1) {
            var haveSemeter = false;
            var haveQuarter = false;
            if (item && item.childrens && item.childrens.length > 0) {
              haveSemeter = true;
              item.childrens.forEach((child) => {
                if (child.grandchildrens && child.grandchildrens.length) {
                  haveQuarter = true;
                }
              });
            } else {
              haveSemeter = false;
            }
          }

          if (
            this.level1Table.editedItem.is_active == false ||
            (haveSemeter == true && haveQuarter == true)
          ) {
            this.loading = true;
            axios
              .post(
                this.getApiUrl +
                  "/school/academicPeriods/updateStartEndDate/" +
                  item.id,
                item,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.loading = false;
                if (
                  response.data.status.error == true &&
                  !Array.isArray(response.data.status.validation_errors)
                ) {
                  this.dialogConfirm = true;
                  // this.disabledSubmitbtn = true;
                  this.disabledSubmitbtn = false;
                } else if (
                  response.data.status.error == true &&
                  response.data.status.validation_errors.length != 0
                ) {
                  // this.disabledSubmitbtn = true;
                  this.disabledSubmitbtn = false;

                  this.validation_errors =
                    response.data.status.validation_errors;
                } else {
                  this.save();
                }
              });
          } else {
            this.snack = true;
            if (haveSemeter == false) {
              this.snackText = this.$i18n.t(
                "Active year should have at least one semester"
              );
            } else if (haveQuarter == false) {
              this.snackText = this.$i18n.t(
                "Active year should have at least one quarter"
              );
            }
            this.snackColor = "error";

            this.disabledSubmitbtn = false;
            this.loading = false;
          }
        } else {
          this.save();
        }
      }
    },
    save() {
      this.dialogConfirm = false;
      if (this.addEditvalid == false) {
        this.checkValidation();
      } else {
        this.disabledSubmitbtn = true;
        let item = this.level1Table.editedItem;
        // if (item) {
        //   console.log(item);
        // }
        if (
          this.selectedItems.length == 1 &&
          this.level1Table.editedIndex == -1
        ) {
          if (this.selectedItems[0].level == 1) {
            // add to the second level.
            this.level1Table.editedItem.parent_id = this.selectedItems[0].id;
            this.level1Table.editedItem.type = "semester";
          } else if (this.selectedItems[0].level == 2) {
            // add to the third level.
            this.level1Table.editedItem.parent_id = this.selectedItems[0].id;
            this.level1Table.editedItem.type = "quarter";
          } else {
            alert("sorry maximum level exceeded");
          }
        }

        if (this.level1Table.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl + "/school/registerAcademicPeriod/" + item.id,
              item,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.disabledSubmitbtn = false;
              this.loading = false;
              // edit mode
              if (response.data.status.error == true) {
                if (
                  Object.keys(response.data.status.validation_errors).length > 0
                ) {
                  this.validation_errors =
                    response.data.status.validation_errors;
                } else {
                  // show snack
                  this.snack = true;
                  this.snackText = response.data.status.message;
                  this.snackColor = "red";
                }
              } else {
                if (item.level == 1) {
                  Object.assign(
                    this.level1Table.years[item.index_level1],
                    this.level1Table.editedItem
                  );
                } else if (item.level == 2) {
                  Object.assign(
                    this.level1Table.years[item.index_level1].childrens[
                      item.index_level2
                    ],
                    this.level1Table.editedItem
                  );
                } else if (item.level == 3) {
                  Object.assign(
                    this.level1Table.years[item.index_level1].childrens[
                      item.index_level2
                    ].grandchildrens[item.index_level3],
                    this.level1Table.editedItem
                  );
                }

                this.validation_errors = [];
                this.close();
              }
            });
        } else {
          if (item["type"] == "") {
            item["type"] = "year";
          }
          // create mode
          axios
            .post(this.getApiUrl + "/school/registerAcademicPeriod", item, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              this.disabledSubmitbtn = false;
              this.loading = false;
              this.level1Table.editedItem.id = response.data.data.id;
              if (this.selectedItems.length == 0) {
                this.level1Table.years.push(this.level1Table.editedItem);
              } else if (this.selectedItems.length == 1) {
                let selectedItem = this.selectedItems[0];
                if (selectedItem.level == 1) {
                  // add to the second level.
                  this.level1Table.years[
                    selectedItem.index_level1
                  ].childrens.push(this.level1Table.editedItem);
                } else if (selectedItem.level == 2) {
                  // for third level table (coming soon..)
                  this.level1Table.years[selectedItem.index_level1].childrens[
                    selectedItem.index_level2
                  ].grandchildrens.push(this.level1Table.editedItem);
                }
              } else {
                // more than 1 element
                // error message
                alert("Please select zero or one row before adding new item");
              }
              // if error retrieved from database
              if (response.data.status.error == true) {
                if (
                  Object.keys(response.data.status.validation_errors).length > 0
                ) {
                  this.validation_errors =
                    response.data.status.validation_errors;
                } else {
                  // show snack
                  this.snack = true;
                  this.snackText = response.data.status.message;
                  this.snackColor = "red";
                }
              } else {
                this.validation_errors = [];
                this.close();
              }

              // changed from this.level1Table.options.page
              this.getDataFromApi(this.page);

              this.level1Table.totalYears = this.level1Table.years.length;
            });
        }
      }
    },
    onFocus() {
      this.validation_errors = [];
    },
    openExpend(item) {
      if (this.selectedItems.length > 0) {
        // selected array already have an item
        if (this.selectedItems[0] == item) {
          // if the same item clicked then clear
          this.selectedItems = [];
          this.grades = [];
          this.academic_period_id = "";
        } else {
          // not the same item then clear then push then retreive other data
          this.selectedItems = [];
          this.grades = [];
          this.academic_period_id = "";
          this.selectedItems.push(item);
          if (
            item.level == 1 &&
            ACL.checkPermission("list-academic-periods-grades")
          ) {
            let id = item.id;
            axios
              .get(this.getApiUrl + "/school/getAcademicPeriodGrade/" + id, {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                  //the token is a variable which holds the token
                },
              })
              .then((response) => {
                this.grades = response.data.data;
                this.academic_period_id = id;
              });
          }
        }
      } else {
        this.selectedItems.push(item);
        if (
          item.level == 1 &&
          ACL.checkPermission("list-academic-periods-grades")
        ) {
          let id = item.id;
          axios
            .get(this.getApiUrl + "/school/getAcademicPeriodGrade/" + id, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            })
            .then((response) => {
              this.grades = response.data.data;
              this.academic_period_id = id;
            });
        }
      }
    },
    changeAdmissionStatus(item) {
      this.level1Table.admissionDialog = true;
      this.level1Table.editedAdmissionItem.id = item.id;
      this.level1Table.editedAdmissionItem.is_addmission_open =
        item.is_addmission_open;
      this.level1Table.editedAdmissionItem.alias = item.alias;
      this.validation_errors.alias = "";
    },
    closeAdmissionStatus() {
      this.level1Table.editedAdmissionItem =
        this.level1Table.defaultAdmissionItem;
      this.level1Table.admissionDialog = false;
      this.level1Table.addmissionStatusError = "";
    },
    saveAdmissionStatus() {
      if (this.level1Table.editedAdmissionItem.is_addmission_open == false) {
        this.level1Table.addmissionStatusError = "";
      }
      let data = this.level1Table.editedAdmissionItem;
      axios
        .post(
          this.getApiUrl + "/school/changeAddmissionStatus/" + data.id,
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error) {
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              this.validation_errors = response.data.status.validation_errors;
            } else {
              this.level1Table.addmissionStatusError =
                response.data.status.message;
              // this.level1Table.admissionDialog = false;
            }
          } else {
            this.closeAdmissionStatus();
          }
          this.getDataFromApi(this.level1Table.options.page);
        });
    },
    openAdmissionLink() {
      let alias = this.level1Table.editedAdmissionItem.alias;
      // this.$router.push({ name: "admission", params: { alias } });
      this.$router.push({ path: `/admission/${alias}` });
    },
    openDialog() {
      console.log("===========================================");
      if (this.selectedItems.length == 0) {
        this.formTitle = this.$i18n.t("New Academic Period");
      } else if (
        this.selectedItems.length > 0 &&
        this.selectedItems[0].level == 1
      ) {
        this.formTitle = this.$i18n.t("Add Semester");
      } else if (
        this.selectedItems.length > 0 &&
        this.selectedItems[0].level == 2
      ) {
        this.formTitle = this.$i18n.t("Add Quarter");
      }

      if (this.$refs.addEditForm) {
        this.resetValidation();
      }
      this.level1Table.dialog = true;
      let _this = this;
      $(document).ready(function () {
        $("#startDate").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.level1Table.editedItem.start_date = dateH;
            } else {
              _this.level1Table.editedItem.start_date = "";
            }
          },
        });
        $("#endDate").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.level1Table.editedItem.end_date = dateH;
            } else {
              _this.level1Table.editedItem.end_date = "";
            }
          },
        });
      });
    },

    clone(id) {
      this.$router.push("/cloneAcademicPeriods/" + id);
    },
  },
  mounted() {
    // this.autoFocusModals();
    if (
      !this.cloneBtnVisible &&
      !this.editBtnVisible &&
      !this.deleteBtnVisible &&
      !this.changeBtnVisible
    ) {
      this.level1Table.headers = this.level1Table.headers.filter(function (
        row
      ) {
        return row.value != "action";
      });
    }
    $(".v-data-footer__select").remove();

    this.page = this.$route.query.page;
    this.getDataFromApi(this.page);

    // axios
    //   .get(this.getApiUrl + "/school/showAcademicPeriod", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.token
    //     }
    //   })
    //   .then(response => {
    //     this.level1Table.years = response.data.data.data;
    //     this.page = response.data.data.current_page;
    //     // this.level1Table.expanded = response.data.data.data;
    //     this.level1Table.totalYears = response.data.data.total;
    //   });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.space-top {
  margin-top: 20px;
}
.space-bottom {
  margin-bottom: 50px;
}
.table-title {
  margin: 20px auto !important;
  width: 45%;
}
:disabled {
  cursor: disabled;
  background-color: gray;
  cursor: not-allowed;
}
.table-title2 {
  margin: 20px auto !important;
  width: 40%;
}
.parentTable::v-deep tbody tr {
  cursor: pointer;
}
.fa-user-graduate {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 5px;
}

/* data input in modals */

#start_date,
#end_date {
  border-bottom: 1px solid #000;
  background-color: #ffffff;
  border-radius: 0;
}
#start_date:focus,
#end_date:focus {
  border-color: #7297ff;
}
.v-application p {
  margin-bottom: 0 !important;
}

.nested-table {
  margin-bottom: 50px;
}
</style>
